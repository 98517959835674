import Vuex from "vuex";
import Vue from "vue";
import {VueReCaptcha} from "vue-recaptcha-v3";
import VueAnalytics from "vue-analytics";
import router from "@/router";

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        consent: {
            googleCaptcha: null,
            googleAnalytics: null,
            cookies: null,
        }
    },
    actions: {
        changeCaptcha({ commit, dispatch }, consent) {
            commit('consentCaptcha', consent)
            if (consent) {
                if (Vue.prototype.$recaptcha === undefined) {
                    console.log("starting recaptcha")
                    Vue.use(VueReCaptcha, {
                        siteKey: '6LcdjJYgAAAAAEJbSb3ZKbsCQpW3rze294RXbdjk',
                        loaderOptions: {
                            explicitRenderParameters: {
                                badge: "bottomleft"
                            }
                        }
                    })
                }
                Vue.prototype.$recaptchaLoaded().then(() => {
                    Vue.prototype.$recaptchaInstance.showBadge()
                })
            } else {
                if (Vue.prototype.$recaptcha) {
                    Vue.prototype.$recaptchaLoaded().then(() => {
                        Vue.prototype.$recaptchaInstance.hideBadge()
                    })
                }
            }
            dispatch('storeOrRemoveCookie')
        },
        changeAnalytics({ commit, dispatch }, consent) {
            commit('consentAnalytics', consent)
            if (consent) {
                if (Vue.prototype.$ga === undefined) {
                    console.log("starting analytics")
                    Vue.use(VueAnalytics, {
                        id: 'UA-98177631-1',
                        checkDuplicatedScript: true,
                        router,
                        disabled: !store.state.consent.googleAnalytics
                    })
                }
                Vue.prototype.$ga.enable()
            } else {
                if (Vue.prototype.$ga) {
                    Vue.prototype.$ga.disable()
                }
            }
            dispatch('storeOrRemoveCookie')
        },
        changeCookies({ commit, dispatch }, consent) {
            commit('consentCookies', consent)
            if (consent) {
                let fontsScript = document.createElement('script')
                fontsScript.setAttribute('src', "https://fast.fonts.net/lt/1.css?apiType=css&c=f30d90c0-7ba6-489c-b29a-7bcf8be9222e&fontids=5304362")
                document.head.appendChild(fontsScript)
            } else {
                Vue.prototype.$cookies.remove('__cf_bm', '/', '.fonts.net')
            }
            dispatch('storeOrRemoveCookie')
        },
        storeOrRemoveCookie({state}) {

            if(state.consent.cookies) {
                Vue.prototype.$cookies.set('consent', state.consent.googleCaptcha+','+state.consent.googleAnalytics)
            } else {
                Vue.prototype.$cookies.remove('consent')
            }
        }
    },
    mutations: {
        consentCaptcha (state, consent) {
            state.consent.googleCaptcha = consent
        },
        consentAnalytics (state, consent) {
            state.consent.googleAnalytics = consent
        },
        consentCookies (state, consent) {
            state.consent.cookies = consent
        },
    }
})
