<template>
  <div>
    <header id="fh5co-header" class="fh5co-cover fh5co-cover-sm" role="banner" style="background-image:url(images/img_bg_4.jpg);">
      <div class="overlay"></div>
      <div class="container">
        <b-row>
          <b-col md="8" class="offset-2 text-center">
            <div class="display-t">
              <div class="display-tc" data-aos="zoom-in">
                <h1>{{ $t('downloads') }}</h1>
                <h2>{{ $t('afpworx-title') }}</h2>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </header>

    <div id="fh5co-services-section">
      <div class="container">
        <b-row>

          <b-col md="4">
            <div class="feature-center">
              <a href="https://afpworx--builds.s3.eu-central-1.amazonaws.com/product/de.hcsystems.dss.installer/AFPWorX-installer_x86-64.exe">
              <span class="icon">
                <i><font-awesome-icon icon="download"></font-awesome-icon></i>
              </span></a>
              <h3>Windows 64bit</h3>
            </div>
          </b-col>

          <b-col md="4">
            <div class="feature-center">
              <a href="https://afpworx--builds.s3.eu-central-1.amazonaws.com/product/de.hcsystems.dss.product/target/products/AFPWorX-linux.gtk.x86_64.tar.gz">
              <span class="icon">
                <i><font-awesome-icon icon="download"></font-awesome-icon></i>
              </span></a>
              <h3>Linux 64bit</h3>
            </div>
          </b-col>

          <b-col md="4">
            <div class="feature-center">
              <a href="https://afpworx--builds.s3.eu-central-1.amazonaws.com/product/de.hcsystems.dss.installer/AFPWorX.pkg">
              <span class="icon">
                <i><font-awesome-icon icon="download"></font-awesome-icon></i>
              </span></a>
              <h3>Mac OS X 64bit</h3>
            </div>
          </b-col>

        </b-row>
      </div>
    </div>

    <Newsletter></Newsletter>

  </div>
</template>

<script>
import Newsletter from "@/components/Newsletter";
export default {
  name: "DownloadsView",
  components:{
    Newsletter
  }
}
</script>

<style scoped>

</style>
