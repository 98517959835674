<template>
  <div id="fh5co-started">
    <div class="container">
      <b-row data-aos="fade-up">
        <b-col md="8" class="offset-2 text-center fh5co-heading">
          <h2>{{ $t('newsletter') }}</h2>
          <p><span>{{ $t('newsletter-desc') }}</span>&nbsp;<a href="dse.html" th:text="#{newsletter.link_description}"></a></p>
        </b-col>
      </b-row>
      <b-row data-aos="fade-up">
        <b-col md="8" class="offset-2">
          <form class="form-inline" action="https://afpworx.us14.list-manage.com/subscribe/post" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" novalidate="novalidate">
            <input type="hidden" name="u" value="94225ed5c2cd9cfd201985d30" />
            <input type="hidden" name="id" value="186c160694" />
            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label for="email" class="sr-only">{{ $t('email') }}</label>
                <input type="email" class="form-control" id="email" name="EMAIL" placeholder="Email" required="required" />
                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_94225ed5c2cd9cfd201985d30_186c160694" tabindex="-1" value="" /></div>
              </div>
            </div>
            <b-col md="6" sm="6">
              <button type="submit" class="btn btn-default btn-block">{{ $t('subscribe') }}</button>
            </b-col>
          </form>
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>
export default {
  name: "Newsletter"
}
</script>

<style scoped>

</style>
