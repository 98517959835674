<template>
  <div>

    <header id="fh5co-header" class="fh5co-cover fh5co-cover-sx" role="banner">
      <div class="overlay"></div>
      <div class="container">
        <b-row class="mt-5">
          <b-col md="8" class="offset-2 text-center">
            <div class="display-t">
              <div class="display-tc" data-aos="zoom-in">
                <h1>{{ $t('privacy-title') }}</h1>
                <h2>{{ $t('privacy-desc') }}</h2>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </header>

    <div class="fh5co-section">
      <div class="container">
        <b-row>
          <b-col md="10">
            <p v-html="$t('privacy-text')"></p>
          </b-col>
        </b-row>
      </div>
    </div>

    <Newsletter/>

  </div>
</template>

<script>
import Newsletter from "@/components/Newsletter";

export default {
  name: "PrivacyView",
  components: {
    Newsletter
  },
  mounted() {
    this.$bvModal.show('consent')
  },
  updated() {
    this.$bvModal.show('consent')
  }
}
</script>

<style scoped>

</style>
