<template xmlns:th="http://www.w3.org/1999/xhtml">
  <div>

    <header id="fh5co-header" class="fh5co-cover fh5co-cover-sm" role="banner" style="background-image:url(images/img_bg_7.jpg);">
      <div class="overlay"></div>
      <div class="container">
        <b-row>
          <b-col md="8" class="offset-2 text-center">
            <div class="display-t">
              <div class="display-tc" data-aos="zoom-in">
                <h1>{{ $t('aboutus') }}</h1>
                <h2>{{ $t('afpworx-title') }}</h2>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </header>

    <div class="fh5co-section" v-if="!googleCaptcha">
      <div class="container">
        <b-row>
          <b-col md="10" data-aos="fade-up">
            <h3>{{ $t('getintouch') }}</h3>
            <p>{{ $t('getintouch-captcha') }}</p>
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <b-col md="6">
            <label for="googleCaptcha">{{ $t('allow') }} Google Captcha</label>
          </b-col>
          <b-col md="6">
            <toggle-button v-model="googleCaptcha" @change="onConsent"/>
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="fh5co-section" v-if="!msgPage && googleCaptcha">
      <div class="container">
        <b-row>
          <b-col md="6" data-aos="fade-up">
            <h3>{{ $t('getintouch') }}</h3>
            <b-row class="form-group">
              <b-col md="6">
                <input type="text" v-model="preName" class="form-control" :placeholder="$t('yourfirstname')"/>
              </b-col>
              <b-col md="6">
                <input type="text" v-model="name" class="form-control" :placeholder="$t('yourlastname')"/>
                <span v-for="error of v$.name.$errors" :key="error.$uid">{{ error.$message }}</span>
              </b-col>
            </b-row>

            <b-row class="form-group">
              <b-col md="12">
                <input type="text" v-model="company" class="form-control" :placeholder="$t('yourcompany')"/>
              </b-col>
            </b-row>

            <b-row class="form-group">
              <b-col md="12">
                <input type="text" v-model="email" class="form-control" :placeholder="$t('youremail')"/>
                <span v-for="error of v$.email.$errors" :key="error.$uid">{{ error.$message }}</span>
              </b-col>
            </b-row>

            <b-row class="form-group">
              <b-col md="12">
                <input type="text" v-model="subject" class="form-control" :placeholder="$t('yoursubject')"/>
                <span v-for="error of v$.subject.$errors" :key="error.$uid">{{ error.$message }}</span>
              </b-col>
            </b-row>

            <b-row class="form-group">
              <b-col md="12">
                <textarea v-model="msg" cols="30" rows="10" class="form-control" :placeholder="$t('yourmessage')"/>
                <span v-for="error of v$.msg.$errors" :key="error.$uid">{{ error.$message }}</span>
              </b-col>
            </b-row>

            <b-row v-if="errors" class="mb-3">
              <b-col md="12">
                <span v-for="error of errors" :key="error">{{ error }}</span>
              </b-col>
            </b-row>

            <p><small class="block" v-html="$t('contact-legal')"></small></p>

            <div class="form-group">
              <button
                  :disabled="submitting"
                  class="g-recaptcha btn btn-primary"
                  @click="submit"
              >
                <span v-if="submitting"><b-spinner small ></b-spinner> {{ $t('sending') }}</span>
                <span v-else>{{ $t('sendmessage') }}</span>
              </button>
            </div>

          </b-col>

          <contact-info></contact-info>

        </b-row>

      </div>
    </div>

    <div class="fh5co-section" v-if="msgPage">
      <div class="container">
        <b-row>
          <b-col md="6" data-aos="fade-up">
            <h3>{{ $t('contact-thanks') }}</h3>
            <p>{{ $t('contact-thanks-desc') }}</p>
          </b-col>
          <contact-info></contact-info>
        </b-row>
      </div>
    </div>

    <Newsletter v-if="msgPage"/>

  </div>
</template>

<script>
import Newsletter from '@/components/Newsletter'
import ContactInfo from "@/components/ContactInfo";
import { email, required } from '@/i18n-validators'
import { useVuelidate } from '@vuelidate/core'
import { apiUrl} from "@/api";
import { ToggleButton } from 'vue-js-toggle-button'
import {mapState} from 'vuex'

export default {
  name: "TrialView",
  components: {
    Newsletter, ContactInfo, ToggleButton
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      submitting: false,
      msgPage: false,
      preName: '',
      name: '',
      company: '',
      email: '',
      subject: '',
      msg: '',
      errors: [],
      googleCaptcha: null,
    }
  },
  validations() {
    return {
      name: { required },
      email: { required, email },
      subject: { required },
      msg: {required},
    }
  },
  computed: mapState({
    googleCaptchaAllowed: state => state.consent.googleCaptcha
  }),
  mounted() {
    this.googleCaptcha = this.googleCaptchaAllowed
  },
  methods: {
    onConsent() {
      this.$store.dispatch('changeCaptcha', this.googleCaptcha)
    },
    async submit() {

      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        return
      }

      await this.$recaptchaLoaded()
      let tokenCaptcha = await this.$recaptcha('sendmessage')

      const msg = {
        fname: this.preName,
        lname: this.name,
        company: this.company,
        email: this.email,
        subject: this.subject,
        msg: this.msg,
        "g-recaptcha-response": tokenCaptcha
      }

      const url = `${apiUrl()}/contact`
      this.submitting = true
      try {
        let response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(msg)
        })

        if (response.status === 200) {
          response = await response.json()
          this.msgPage = true
        } else {
          response = await response.json()
          this.errors.push(response.errorMessage)
        }


      } catch (e) {
        console.log(e)
      }
      this.submitting = false
    }
  }
}
</script>

<style scoped>

</style>
