<template>
  <b-col md="5" offset-md="1" data-aos="fade-up">
    <div class="fh5co-contact-info">
      <h3>{{ $t('contactinformation') }}</h3>
      <ul>
        <li class="address">AFPWorX.com GmbH<br/>Yan Hackl-Feldbusch<br/>Gartenweg 1<br/>34582 Borken<br/>GERMANY</li>
        <li class="email"><a href="mailto:box@afpworx.com">box@afpworx.com</a></li>
        <li class="url"><a href="http://www.afpworx.com">www.afpworx.com</a></li>
      </ul>
    </div>
  </b-col>
</template>

<script>
export default {
  name: "ContactInfo"
}
</script>

<style scoped>

</style>
