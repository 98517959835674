<template>
  <div>

    <header id="fh5co-header" class="fh5co-cover fh5co-cover-sm" role="banner" style="background-image:url(images/img_bg_3.jpg);">
      <div class="overlay"></div>
      <div class="container">
        <b-row>
          <b-col md="8" class="offset-2 text-center">
            <div class="display-t">
              <div class="display-tc" data-aos="zoom-in">
                <h1>{{ $t('screenshots') }}</h1>
                <h2>{{ $t('seeinaction') }}</h2>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </header>

    <div id="fh5co-portfolio">
      <div class="container">
        <b-row data-aos="fade-up">
          <b-col md="8" class="offset-2 text-center fh5co-heading">
            <p>{{ $t('selectThumbnails') }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4" sm="4" class="text-center" data-aos="fade-up">
            <div class="work-grid" style="background-image: url(images/screen_1-pre.png);">
              <div class="desc">
                <h3><a class="image-popup-link" href="images/screen_1.png">{{ $t('clickableViewer') }}</a></h3>
                <span class="cat"></span>
                <p>
                  <span class="download"><a class="image-popup-link" href="images/screen_1.png"><i><font-awesome-icon icon="up-right-from-square"/></i></a></span>
                </p>
              </div>
            </div>
          </b-col>
          <b-col md="4" sm="4" class="text-center" data-aos="fade-up">
            <div class="work-grid" style="background-image: url(images/screen_2-pre.png);">
              <div class="desc">
                <h3><a class="image-popup-link" href="images/screen_2.png">{{ $t('findReferences') }}</a></h3>
                <span class="cat"></span>
                <p>
                  <span class="download"><a class="image-popup-link" href="images/screen_2.png"><i><font-awesome-icon icon="up-right-from-square"/></i></a></span>
                </p>
              </div>
            </div>
          </b-col>
          <b-col md="4" sm="4" class="text-center" data-aos="fade-up">
            <div class="work-grid" style="background-image: url(images/screen_3-pre.png);">
              <div class="desc">
                <h3><a class="image-popup-link" href="images/screen_3.png">{{ $t('validateSpec') }}</a></h3>
                <span class="cat"></span>
                <p>
                  <span class="download"><a class="image-popup-link" href="images/screen_3.png"><i><font-awesome-icon icon="up-right-from-square"/></i></a></span>
                </p>
              </div>
            </div>
          </b-col>
          <b-col md="4" sm="4" class="text-center" data-aos="fade-up">
            <div class="work-grid" style="background-image: url(images/screen_4-pre.png);">
              <div class="desc">
                <h3><a class="image-popup-link" href="images/screen_4.png">{{ $t('workPTOCA') }}</a></h3>
                <span class="cat"></span>
                <p>
                  <span class="download"><a class="image-popup-link" href="images/screen_4.png"><i><font-awesome-icon icon="up-right-from-square"/></i></a></span>
                </p>
              </div>
            </div>
          </b-col>
          <b-col md="4" sm="4" class="text-center" data-aos="fade-up">
            <div class="work-grid" style="background-image: url(images/screen_5-pre.png);">
              <div class="desc">
                <h3><a class="image-popup-link" href="images/screen_5.png">{{ $t('fulltextsearch') }}</a></h3>
                <span class="cat"></span>
                <p>
                  <span class="download"><a class="image-popup-link" href="images/screen_5.png"><i><font-awesome-icon icon="up-right-from-square"/></i></a></span>
                </p>
              </div>
            </div>
          </b-col>
          <b-col md="4" sm="4" class="text-center" data-aos="fade-up">
            <div class="work-grid" style="background-image: url(images/screen_6-pre.png);">
              <div class="desc">
                <h3><a class="image-popup-link" href="images/screen_6.png">{{ $t('inspectFonts') }}</a></h3>
                <span class="cat"></span>
                <p>
                  <span class="download"><a class="image-popup-link" href="images/screen_6.png"><i><font-awesome-icon icon="up-right-from-square"/></i></a></span>
                </p>
              </div>
            </div>
          </b-col>
          <b-col md="4" sm="4" class="text-center" data-aos="fade-up">
            <div class="work-grid" style="background-image: url(images/screen_7-pre.png);">
              <div class="desc">
                <h3><a class="image-popup-link" href="images/screen_7.png">{{ $t('editSF') }}</a></h3>
                <span class="cat"></span>
                <p>
                  <span class="download"><a class="image-popup-link" href="images/screen_7.png"><i><font-awesome-icon icon="up-right-from-square"/></i></a></span>
                </p>
              </div>
            </div>
          </b-col>
          <b-col md="4" sm="4" class="text-center" data-aos="fade-up">
            <div class="work-grid" style="background-image: url(images/screen_8-pre.png);">
              <div class="desc">
                <h3><a class="image-popup-link" href="images/screen_8.png">{{ $t('addSF') }}</a></h3>
                <span class="cat"></span>
                <p>
                  <span class="download"><a class="image-popup-link" href="images/screen_8.png"><i><font-awesome-icon icon="up-right-from-square"/></i></a></span>
                </p>
              </div>
            </div>
          </b-col>
          <b-col md="4" sm="4" class="text-center" data-aos="fade-up">
            <div class="work-grid" style="background-image: url(images/screen_9-pre.png);">
              <div class="desc">
                <h3><a class="image-popup-link" href="images/screen_9.png">{{ $t('hexViewer') }}</a></h3>
                <span class="cat"></span>
                <p>
                  <span class="download"><a class="image-popup-link" href="images/screen_9.png"><i><font-awesome-icon icon="up-right-from-square"/></i></a></span>
                </p>
              </div>
            </div>
          </b-col>
          <b-col md="4" sm="4" class="text-center" data-aos="fade-up">
            <div class="work-grid" style="background-image: url(images/screen_10-pre.png);">
              <div class="desc">
                <h3><a class="image-popup-link" href="images/screen_10.png">{{ $t('preflight') }}</a></h3>
                <span class="cat"></span>
                <p>
                  <span class="download"><a class="image-popup-link" href="images/screen_10.png"><i><font-awesome-icon icon="up-right-from-square"/></i></a></span>
                </p>
              </div>
            </div>
          </b-col>
          <b-col md="4" sm="4" class="text-center" data-aos="fade-up">
            <div class="work-grid" style="background-image: url(images/screen_11-pre.png);">
              <div class="desc">
                <h3><a class="image-popup-link" href="images/screen_11.png">{{ $t('objectBrowser') }}</a></h3>
                <span class="cat"></span>
                <p>
                  <span class="download"><a class="image-popup-link" href="images/screen_11.png"><i><font-awesome-icon icon="up-right-from-square"/></i></a></span>
                </p>
              </div>
            </div>
          </b-col>
          <b-col md="4" sm="4" class="text-center" data-aos="fade-up">
            <div class="work-grid" style="background-image: url(images/screen_12-pre.png);">
              <div class="desc">
                <h3><a class="image-popup-link" href="images/screen_12.png">{{ $t('boundaryViolation') }}</a></h3>
                <span class="cat"></span>
                <p>
                  <span class="download"><a class="image-popup-link" href="images/screen_12.png"><i><font-awesome-icon icon="up-right-from-square"/></i></a></span>
                </p>
              </div>
            </div>
          </b-col>

        </b-row>
      </div>
    </div>

    <Newsletter/>

  </div>
</template>

<script>
import Newsletter from '@/components/Newsletter'
import $ from "jquery";

export default {
  name: "ScreenshotsView",
  components: {
    Newsletter
  },
  mounted() {

    $('.image-popup-link').magnificPopup({
      type: 'image'
      // other options
    });

  }
}
</script>

<style scoped>

</style>
