<template>
  <b-modal id="consent" ref="consent" :title="$t('modal-privacy-title')" @ok="handleOk" @show="reset">
    <b-container fluid>
      <p>{{ $t('modal-privacy-desc') }}</p>
      <ul>
        <li>{{ $t('googleCaptcha-desc') }}</li>
        <li>{{ $t('googleAnalytics-desc') }}</li>
        <li>{{ $t('cookies-desc') }}</li>
      </ul>
      <p>{{ $t('consent-moreDetails') }}</p>
      <b-row class="align-items-center">
        <b-col md="10">
          <label for="googleCaptcha">{{ $t('allow') }} Google Captcha</label>
        </b-col>
        <b-col md="2">
          <toggle-button v-model="googleCaptcha"/>
        </b-col>
        <b-col md="10">
          <label for="googleAnalytics">{{ $t('allow') }}  Google Analystics</label>
        </b-col>
        <b-col md="2">
          <toggle-button v-model="googleAnalytics"/>
        </b-col>
        <b-col md="10">
          <label for="cookies">{{ $t('allow') }}  Cookies</label>
        </b-col>
        <b-col md="2">
          <toggle-button v-model="cookies"/>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button'
import {mapActions, mapState} from 'vuex'

export default {
  name: "Consent",
  components: {
    ToggleButton
  },
  data() {
    return {
      googleCaptcha: null,
      googleAnalytics: null,
      cookies: null,
    }
  },
  mounted() {
    if (this.$store.state.consent.cookies === null) {
      this.reset()
      this.$bvModal.show('consent')
    }
  },
  computed: {
    ...mapState({
      captchaAllowed: (state) => state.consent.googleCaptcha,
      analyticsAllowed: (state) => state.consent.googleAnalytics,
      cookiesAllowed: (state) => state.consent.cookies,
    }),
  },
  methods: {
    ...mapActions([
        'changeCaptcha', 'changeAnalytics', 'changeCookies'
    ]),
    reset() {
      this.googleCaptcha = this.$store.state.consent.googleCaptcha
      this.googleAnalytics = this.$store.state.consent.googleAnalytics
      this.cookies = this.$store.state.consent.cookies
      if (this.cookies === null) {
        this.googleCaptcha = true
        this.googleAnalytics = true
        this.cookies = true
      }
    },
    async handleOk() {
      this.changeCaptcha(this.googleCaptcha)
      this.changeAnalytics(this.googleAnalytics)
      this.changeCookies(this.cookies)
    }
  }
}
</script>

<style scoped>

</style>
