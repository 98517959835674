<template>
  <div>
    <header id="fh5co-header" class="fh5co-cover fh5co-cover-sx" role="banner" style="background-image:url(images/img_bg_9.jpg);">
      <div class="overlay"></div>
      <div class="container">
        <b-row>
          <b-col md="8" class="offset-2 text-center">
            <div class="display-t">
              <div class="display-tc" data-aos="zoom-in">
                <h1>{{ $t('prices') }}</h1>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </header>

    <div class="fh5co-section">
      <div class="container">
        <b-row>
          <b-col md="4" data-aos="fade-up">
            <h3 class="text-center">AFPWorX Standard</h3>
            <b-img fluid :src="require('@/assets/images/package-std.png')" />
            <p class="text-right">1.499,00 € {{ $t('inclVAT') }}</p>
            <p>{{ $t('prices-std-desc') }}</p>
          </b-col>
          <b-col md="4" data-aos="fade-up">
            <h3 class="text-center">AFPWorX Pro</h3>
            <b-img fluid :src="require('@/assets/images/package-pro.png')" />
            <p class="text-right">
              2.900,00 € {{ $t('inclVAT') }}
            </p>
            <p>{{ $t('prices-pro-desc') }}</p>
          </b-col>
          <b-col md="4" data-aos="fade-up">
            <h3 class="text-center">AFPWorX Pro+</h3>
            <b-img fluid :src="require('@/assets/images/package-proplus.png')" />
            <p class="text-right">
              4.490,00 € {{ $t('inclVAT') }}
            </p>
            <p>{{ $t('prices-proplus-desc') }}</p>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col md="10" data-aos="fade-up">
            <h2>{{ $t('featureComparison') }}</h2>
            <b-table striped :items="featureMatrix"></b-table>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col md="10" data-aos="fade-up">
            <h2>{{ $t('buy') }}</h2>
            <p v-html="$t('sendRFQ')"></p>
          </b-col>
        </b-row>


      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "PriceView",
  data() {
    return {
      featureMatrix: [
        {Feature: this.$t('viewPrintEdit'), Standard: "✅", Pro: "✅", ProPlus: "✅"},
        {Feature: this.$t('pdf'), Standard: "✅", Pro: "✅", ProPlus: "✅"},
        {Feature: this.$t('fulltext'), Standard: "✅", Pro: "✅", ProPlus: "✅"},
        {Feature: this.$t('validate'), Standard: "❌", Pro: "✅", ProPlus: "✅"},
        {Feature: this.$t('autofix'), Standard: "❌", Pro: "❌", ProPlus: "✅"},
        {Feature: this.$t('boundingbox'), Standard: "❌", Pro: "❌", ProPlus: "✅"},
        {Feature: this.$t('preflight'), Standard: "❌", Pro: "❌", ProPlus: "✅"},
      ]
    }
  }
}
</script>

<style scoped>

</style>
