<template>
  <div>

    <header id="fh5co-header" class="fh5co-cover fh5co-cover-sm" role="banner" style="background-image:url(images/img_bg_2.jpg);">
      <div class="overlay"></div>
      <div class="container">
        <b-row>
          <b-col md="8" class="offset-2 text-center">
            <div class="display-t">
              <div class="display-tc" data-aos="zoom-in">
                <h1>{{ $t('features-title') }}</h1>
                <h2>{{ $t('afpworx-title') }}</h2>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </header>

    <div id="fh5co-services-section">
      <div class="container">
        <b-row>
        <b-col md="4" sm="4">
          <div class="feature-center">
					<span class="icon">
						<i><font-awesome-icon icon="eye"/></i>
					</span>
            <h3>{{ $t('afpviewer') }}</h3>
            <p>{{ $t('afpviewer-desc') }}</p>
          </div>
        </b-col>
        <b-col md="4" sm="4">
          <div class="feature-center">
					<span class="icon">
						<i><font-awesome-icon icon="tools"/></i>
					</span>
            <h3>{{ $t('editafp') }}</h3>
            <p>{{ $t('editafp-desc') }}</p>
          </div>
        </b-col>
        <b-col md="4" sm="4">
          <div class="feature-center">
					<span class="icon">
						<i><font-awesome-icon icon="copy"/></i>
					</span>
            <h3>{{ $t('copypaste') }}</h3>
            <p>{{ $t('copypaste-desc') }}</p>
          </div>
        </b-col>

        <b-col md="4" sm="4">
          <div class="feature-center">
					<span class="icon">
						<i><font-awesome-icon icon="file-export"/></i>
					</span>
            <h3>{{ $t('extractResources') }}</h3>
            <p>{{ $t('extractResources-desc') }}</p>
          </div>
        </b-col>
        <b-col md="4" sm="4">
          <div class="feature-center">
					<span class="icon">
						<i><font-awesome-icon icon="link"/></i>
					</span>
            <h3>{{ $t('includeResources') }}</h3>
            <p>{{ $t('includeResources-desc') }}</p>
          </div>
        </b-col>
        <b-col md="4" sm="4">
          <div class="feature-center">
					<span class="icon">
						<i><font-awesome-icon icon="lock"/></i>
					</span>
            <h3>{{ $t('anonymize') }}</h3>
            <p>{{ $t('anonymize-desc') }}</p>
          </div>
        </b-col>

        <b-col md="4" sm="4">
          <div class="feature-center">
                    <span class="icon">
                        <i><font-awesome-icon icon="font"/></i>
                    </span>
            <h3>{{ $t('fontinspector') }}</h3>
            <p>{{ $t('fontinspector-desc') }}</p>
          </div>
        </b-col>

        <b-col md="4" sm="4">
          <div class="feature-center">
                    <span class="icon">
                        <i><font-awesome-icon icon="bullseye"/></i>
                    </span>
            <h3>{{ $t('findReferences') }}</h3>
            <p>{{ $t('findReferences-desc') }}</p>
          </div>
        </b-col>
        <b-col md="4" sm="4">
          <div class="feature-center">
                    <span class="icon">
                        <i><font-awesome-icon icon="infinity"/></i>
                    </span>
            <h3>{{ $t('hexViewer') }}</h3>
            <p>{{ $t('hexViewer-desc') }}</p>
          </div>
        </b-col>

        <b-col md="4" sm="4">
          <div class="feature-center">
                    <span class="icon">
                        <i><font-awesome-icon icon="search"/></i>
                    </span>
            <h3>{{ $t('fulltextsearch') }}</h3>
            <p>{{ $t('fulltextsearch-desc') }}</p>
          </div>
        </b-col>
        <b-col md="4" sm="4">
          <div class="feature-center">
                    <span class="icon">
                        <i><font-awesome-icon icon="warning"/></i>
                    </span>
            <h3>{{ $t('validation') }}</h3>
            <p>{{ $t('validation-desc') }}</p>
          </div>
        </b-col>
        <b-col md="4" sm="4">
          <div class="feature-center">
                    <span class="icon">
                        <i><font-awesome-icon icon="check"/></i>
                    </span>
            <h3>{{ $t('autocorrection') }}</h3>
            <p>{{ $t('autocorrection-desc') }}</p>
          </div>
        </b-col>

        <b-col md="4" sm="4">
          <div class="feature-center">
                    <span class="icon">
                        <i><font-awesome-icon icon="file-circle-check"/></i>
                    </span>
            <h3>{{ $t('oob') }}</h3>
            <p>{{ $t('oob-desc') }}</p>
          </div>
        </b-col>

        <b-col md="4" sm="4">
          <div class="feature-center">
                    <span class="icon">
                        <i><font-awesome-icon icon="map"/></i>
                    </span>
            <h3>{{ $t('browser') }}</h3>
            <p>{{ $t('browser-desc') }}</p>
          </div>
        </b-col>
        <b-col md="4" sm="4">
          <div class="feature-center">
                    <span class="icon">
                        <i><font-awesome-icon icon="life-ring"/></i>
                    </span>
            <h3>{{ $t('help') }}</h3>
            <p>{{ $t('help-desc') }}</p>
          </div>
        </b-col>
        </b-row>
      </div>
    </div>

    <Newsletter/>

  </div>
</template>

<script>
import Newsletter from '@/components/Newsletter'

export default {
  name: "FeaturesView",
  components: {
    Newsletter
  },
}
</script>

<style scoped>

</style>
