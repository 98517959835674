<template xmlns:th="http://www.w3.org/1999/xhtml">
  <div id="app">

    <consent></consent>

    <div class="fh5co-loader"></div>

    <div id="page">

      <nav class="fh5co-nav" role="navigation">
        <div class="container">
          <b-row>
            <b-col md="1">
              <div id="fh5co-logo">
                <router-link to="/">AFPWorX</router-link>
              </div>
            </b-col>
            <b-col md="11" class="text-right menu-1">
              <ul>
                <li>
                  <router-link to="/">{{ $t('home') }}</router-link>
                </li>
                <li>
                  <router-link to="/features">{{ $t('features') }}</router-link>
                </li>
                <li>
                  <router-link to="/screenshots">{{ $t('screenshots') }}</router-link>
                </li>
                <li>
                  <router-link to="/blog">{{ $t('blog') }}</router-link>
                </li>
                <li>
                  <router-link to="/trial">{{ $t('testit') }}</router-link>
                </li>
                <li>
                  <router-link to="/contact">{{ $t('contact') }}</router-link>
                </li>
                <li>
                  <router-link to="/prices">{{ $t('prices') }}</router-link>
                </li>
                <li>
                  <router-link to="/dse">{{ $t('dse-nav') }}</router-link>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </nav>
      <router-view/>

      <footer id="fh5co-footer" role="contentinfo">
        <div class="container">
          <b-row class="copyright">
            <b-col md="12" class="text-center">
              <p>
                <small class="block">AFPWorX.com GmbH, Gartenweg 1, 34582 Borken, GERMANY, <a
                    href="mailto:box@afpworx.com">box@afpworx.com</a></small>
              </p>
              <p>
                <small class="block">&copy; 2017 - {{ new Date().getFullYear() }} All Rights Reserved.</small>
              </p>
            </b-col>
          </b-row>

        </div>
      </footer>

    </div>

    <div class="gototop js-top" v-show="toTopArrowVisible" v-on:click="scrollToTop">
      <i><font-awesome-icon icon="arrow-up"></font-awesome-icon></i>
    </div>

  </div>
</template>

<script>
import AOS from 'aos'
import Consent from "@/components/Consent";
import $ from 'jquery'

export default {
  components: {
    Consent
  },
  data() {
    return {
      toTopArrowVisible: false,
    }
  },
  created() {
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollListener)
  },
  updated() {
  },
  mounted() {
    AOS.init()

    window.addEventListener('scroll', this.scrollListener)

    var mobileMenuOutsideClick = function () {

      $(document).click(function (e) {
        var container = $("#fh5co-offcanvas, .js-fh5co-nav-toggle");
        if (!container.is(e.target) && container.has(e.target).length === 0) {

          if ($('body').hasClass('offcanvas')) {

            $('body').removeClass('offcanvas');
            $('.js-fh5co-nav-toggle').removeClass('active');

          }


        }
      });

    };


    var offcanvasMenu = function () {

      $('#page').prepend('<div id="fh5co-offcanvas" />');
      $('#page').prepend('<a href="#" class="js-fh5co-nav-toggle fh5co-nav-toggle fh5co-nav-white"><i></i></a>');
      var clone1 = $('.menu-1 > ul').clone();
      $('#fh5co-offcanvas').append(clone1);
      var clone2 = $('.menu-2 > ul').clone();
      $('#fh5co-offcanvas').append(clone2);

      $('#fh5co-offcanvas .has-dropdown').addClass('offcanvas-has-dropdown');
      $('#fh5co-offcanvas')
          .find('li')
          .removeClass('has-dropdown');

      // Hover dropdown menu on mobile
      $('.offcanvas-has-dropdown').mouseenter(function () {
        var $this = $(this);

        $this
            .addClass('active')
            .find('ul')
            .slideDown(500, 'easeOutExpo');
      }).mouseleave(function () {

        var $this = $(this);
        $this
            .removeClass('active')
            .find('ul')
            .slideUp(500, 'easeOutExpo');
      });


      $(window).resize(function () {

        if ($('body').hasClass('offcanvas')) {

          $('body').removeClass('offcanvas');
          $('.js-fh5co-nav-toggle').removeClass('active');

        }
      });
    };


    var burgerMenu = function () {

      $('body').on('click', '.js-fh5co-nav-toggle', function (event) {
        var $this = $(this);


        if ($('body').hasClass('overflow offcanvas')) {
          $('body').removeClass('overflow offcanvas');
        } else {
          $('body').addClass('overflow offcanvas');
        }
        $this.toggleClass('active');
        event.preventDefault();

      });
    };

    var dropdown = function () {

      $('.has-dropdown').mouseenter(function () {

        var $this = $(this);
        $this
            .find('.dropdown')
            .css('display', 'block')
            .addClass('animated-fast fadeInUpMenu');

      }).mouseleave(function () {
        var $this = $(this);

        $this
            .find('.dropdown')
            .css('display', 'none')
            .removeClass('animated-fast fadeInUpMenu');
      });

    };

    // Loading page
    var loaderPage = function () {
      $(".fh5co-loader").fadeOut("slow");
    };

    $(function () {
      mobileMenuOutsideClick();
      offcanvasMenu();
      burgerMenu();
      dropdown();
      loaderPage();
    });


  },
  methods: {
    scrollToTop() {
      const steps = 100
      let start = window.scrollY
      var progress = 0

      this.intervalId = setInterval(() => {
        if (progress >= 1) {
          clearInterval(this.intervalId)
        }
        window.scroll(0, start - this.easeInOutQuint(progress) * start )
        progress += (1/steps)
      }, 20)
    },
    scrollListener() {
      this.toTopArrowVisible = window.scrollY > 200
    },
    easeInOutQuint(x) {
      return (x<0.5) ? (16*x*x*x*x*x) : (1 - Math.pow(-2 * x + 2, 5) / 2)
    },
  }
}
</script>

<style>
</style>
