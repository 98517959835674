import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import FeaturesView from "@/views/FeaturesView";
import ScreenshotsView from "@/views/ScreenshotsView";
import BlogView from "@/views/BlogView";
import TrialView from "@/views/TrialView";
import ContactView from "@/views/ContactView";
import PrivacyView from "@/views/PrivacyView";
import PriceView from "@/views/PriceView";
import MemoryView from "@/views/MemoryView";
import DownloadsView from "@/views/DownloadsView";
import EulaView from "@/views/EulaView";
import LicenseView from "@/views/LicenseView";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/features',
    name: 'features',
    component: FeaturesView
  },
  {
    path: '/screenshots',
    name: 'screenshots',
    component: ScreenshotsView
  },
  {
    path: '/blog',
    name: 'blog',
    component: BlogView
  },
  {
    path: '/trial',
    name: 'trial',
    component: TrialView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/prices',
    name: 'prices',
    component: PriceView
  },
  {
    path: '/dse',
    name: 'dse',
    component: PrivacyView
  },
  {
    path: '/memory',
    name: 'memory',
    alias: '/memory.html',
    component: MemoryView
  },
  {
    path: '/downloads',
    name: 'downloads',
    alias: ['/download', '/download.html', '/downloads.html'],
    component: DownloadsView
  },
  {
    path: '/eula',
    name: 'eula',
    alias: '/eula.html',
    component: EulaView
  },
  {
    path: '/license',
    name: 'license',
    alias: ['/license.html', '/activation'],
    component: LicenseView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
