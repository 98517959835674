import awsmobile from "@/aws-exports";

export const apiUrl = () => {
    for (const api of awsmobile.aws_cloud_logic_custom) {
        if (api.name === 'LicenseManagerPublicAPI') {
            return api.endpoint
        }
    }
    return null
}
