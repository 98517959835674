/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:c4ea5064-19a9-40ac-8410-aa6184dad072",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_73PT9GGNA",
    "aws_user_pools_web_client_id": "58s4bdab5ujjkto829c70v8rno",
    "oauth": {
        "domain": "licensemanagerf85ce35e-f85ce35e-prod.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "myapp://",
        "redirectSignOut": "myapp://",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://d6lnted67bdnxmqf34fsyjzaf4.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "LicenseManagerPublicAPI",
            "endpoint": "https://i36mpc39ji.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "ServiceAPI",
            "endpoint": "https://54nzeqx2uc.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        }
    ]
};


export default awsmobile;
