<template>
  <div>
    <header id="fh5co-header" class="fh5co-cover fh5co-cover-sx" role="banner">
      <div class="overlay"></div>
      <div class="container">
        <b-row class="mt-5">
          <b-col md="8" class="offset-2 text-center">
            <div class="display-t">
              <div class="display-tc" data-aos="zoom-in">
                <h1>{{ $t('memory.title') }}</h1>
                <h2>{{ $t('memory.desc') }}</h2>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </header>

    <div id="fh5co-services-section">
      <div class="container">
        <b-row>
          <b-col md="10">
            <p v-html="$t('memory.text')"></p>
          </b-col>
        </b-row>
      </div>
    </div>

    <Newsletter/>

  </div>
</template>

<script>
import Newsletter from "@/components/Newsletter";
export default {
  name: "MemoryView",
  components:{
    Newsletter
  }
}
</script>

<style scoped>

</style>
