<template>
  <div>

    <header id="fh5co-header" class="fh5co-cover fh5co-cover-sm" role="banner" style="background-image:url(images/img_bg_8.jpg);">
      <div class="overlay"></div>
      <div class="container">
        <b-row>
          <b-col md="8" class="offset-2 text-center">
            <div class="display-t">
              <div class="display-tc" data-aos="zoom-in">
                <h1>{{ $t('blog-entries.title') }}</h1>
                <h2>{{ $t('blog-entries.desc') }}</h2>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </header>

    <div id="fh5co-services-section">
      <div class="container">
        <b-row class="mb-5" v-for="entry in blogEntries" :key="entry">
          <b-col md="4" sm="4" data-aos="fade-right">
            <div class="fh5co-staff">
              <img src="images/avatar.png" alt="Yan" />
              <h3>Yan Hackl-Feldbusch</h3>
              <strong class="role">{{ $t('owner') }}</strong>
            </div>
          </b-col>
          <b-col md="8" sm="8" data-aos="fade-left">
            <p v-html="$t('blog-entries.'+entry+'.text')"></p>
          </b-col>
        </b-row>

      </div>
    </div>


    <Newsletter/>

  </div>
</template>

<script>
import Newsletter from '@/components/Newsletter'

export default {
  name: "BlogView",
  components: {
    Newsletter
  },
  data() {
    return {
      blogEntries: ["2", "1"]
    }
  }
}
</script>

<style scoped>

</style>
