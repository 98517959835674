<template xmlns:th="http://www.w3.org/1999/xhtml">
  <div class="home">
    <header id="fh5co-header" class="fh5co-cover" role="banner" style="background-image:url('/images/img_bg_1.jpg');">
      <div class="overlay"></div>
      <div class="container">
        <b-row>
          <b-col md="10" class="offset-1 text-center">
            <div class="display-t">
              <div class="display-tc" data-aos="zoom-in">
                <img src="images/web-logo-180.png" alt="AFPWorX Logo"/>
                <h1>{{ $t('claim1') }}</h1>
                <h2>{{ $t('claim2') }}</h2>
                <p>
                  <router-link to="/trial" class="btn btn-default">{{ $t('gettrial') }}</router-link>
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </header>

    <div class="container">
      <b-row>
        <div id="fh5co-section">
          <div class="col-nineth">
            <b-row>
              <div class="col-third">
                <b-col md="12">
								<span class="icon">
                  <i><font-awesome-icon icon="search"/></i>
								</span>
                  <div class="desc">
                    <h3>{{ $t('analyze') }}</h3>
                    <p>{{ $t('analyze-desc') }}</p>
                    <p><a href="#analyze" class="btn btn-primary">{{ $t('learnmore') }}</a></p>
                  </div>
                </b-col>
              </div>
              <div class="col-third">
                <b-col md="12">
								<span class="icon">
                  <i><font-awesome-icon icon="lightbulb"/></i>
								</span>
                  <div class="desc">
                    <h3>{{ $t('understand') }}</h3>
                    <p>{{ $t('understand-desc') }}</p>
                    <p><a href="#understand" class="btn btn-primary">{{ $t('learnmore') }}</a></p>
                  </div>
                </b-col>
              </div>
              <div class="col-third">
                <b-col md="12">
								<span class="icon">
                  <i><font-awesome-icon icon="screwdriver-wrench"/></i>
								</span>
                  <div class="desc">
                    <h3>{{ $t('fix') }}</h3>
                    <p>{{ $t('fix-desc') }}</p>
                    <p><a href="#fix" class="btn btn-primary">{{ $t('learnmore') }}</a></p>
                  </div>
                </b-col>
              </div>
            </b-row>
          </div>
          <div class="col-forth fh5co-video fh5co-bg" style="background-image: url(images/image_1.jpg)">
            <!--  <a href="https://www.youtube.com/embed/16fwI27FrMU" class="popup-youtube"><i class="icon-video2"></i></a>  -->
            <div class="overlay"></div>
          </div>
        </div>
      </b-row>
    </div>

    <div class="fh5co-bg-section">
      <div class="container">
        <b-row>
          <div id="fh5co-features-2">
            <div class="col-feature-9">
              <b-row>
                <b-col md="12" class="fh5co-heading" data-aos="fade-up">
                  <h2>{{ $t('solve') }}</h2>
                  <p>{{ $t('solve-desc') }}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="7" sm="7" style="right: 16.66667%" data-aos="fade-right">
                  <div class="feature-image">
                    <img src="images/work_1.png" alt="AFPWorX screenshot"/>
                  </div>
                </b-col>
                <b-col md="5" sm="5">
                  <div class="feature-left" data-aos="fade-left">
									<span class="icon">
										<i><font-awesome-icon class="align-top" icon="check"/></i>
									</span>
                    <div class="feature-copy">
                      <h3>{{ $t('fullmodca') }}</h3>
                      <p>{{ $t('fullmodca-desc') }}</p>
                    </div>
                  </div>

                  <div class="feature-left" data-aos="fade-left">
									<span class="icon">
										<i><font-awesome-icon class="align-top" icon="check"/></i>
									</span>
                    <div class="feature-copy">
                      <h3>{{ $t('viewer') }}</h3>
                      <p>{{ $t('viewer-desc') }}</p>
                    </div>
                  </div>

                  <div class="feature-left" data-aos="fade-left">
									<span class="icon">
										<i><font-awesome-icon class="align-top" icon="check"/></i>
									</span>
                    <div class="feature-copy">
                      <h3>{{ $t('fast') }}</h3>
                      <p>{{ $t('fast-desc') }}</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>

            <div class="col-feature-3">
              <div id="fh5co-counter" class="fh5co-bg fh5co-counter"
                   v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }">
                <div class="row">
                  <div class="display-t">
                    <div class="display-tc">
                      <b-col md="12" data-aos="fade-up">
                        <div class="feature-center">
                          <count-to ref="counter_sf" :autoplay="false" :startVal="0" :endVal="151" :duration="5000"
                                    class="counter"/>
                          <span class="counter-label">{{ $t('structuredFields') }}</span>

                        </div>
                      </b-col>
                      <b-col data-aos="fade-up">
                        <div class="feature-center">
                          <count-to ref="counter_cs" :autoplay="false" :startVal="0" :endVal="25" :duration="5000"
                                    class="counter"/>
                          <span class="counter-label">{{ $t('chainedSequences') }}</span>
                        </div>
                      </b-col>
                      <b-col md="12" data-aos="fade-up">
                        <div class="feature-center">
                          <count-to ref="counter_sdf" :autoplay="false" :startVal="0" :endVal="28" :duration="5000"
                                    class="counter"/>
                          <span class="counter-label">{{ $t('sdf') }}</span>
                        </div>
                      </b-col>
                      <b-col md="12" data-aos="fade-up">
                        <div class="feature-center">
                          <count-to ref="counter_triplets" :autoplay="false" :startVal="0" :endVal="133"
                                    :duration="5000" class="counter"/>
                          <span class="counter-label">{{ $t('triplets') }}</span>
                        </div>
                      </b-col>
                      <b-col md="12" data-aos="fade-up">
                        <div class="feature-center">
                          <count-to ref="counter_goca" :autoplay="false" :startVal="0" :endVal="57" :duration="5000"
                                    class="counter"/>
                          <span class="counter-label">{{ $t('goca') }}</span>
                        </div>
                      </b-col>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-row>
      </div>
    </div>

    <div id="fh5co-portfolio">
      <div class="container">
        <b-row data-aos="fade-up">
          <b-col md="8" class="offset-2 text-center fh5co-heading">
            <h2>AFPWorX</h2>
            <p>{{ $t('designedDriverSeat') }}</p>
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <b-col md="4" data-aos="fade-right">
              <a name="analyze"></a>
              <h3>{{ $t('analyzeModify') }}</h3>
              <p>{{ $t('analyzeModify-desc') }}</p>
              <ul class="list-nav">
                <li><i class="icon-check"><font-awesome-icon icon="check"/></i><span>{{ $t('analyzeModify-navigation') }}</span>
                </li>
                <li><i class="icon-check"><font-awesome-icon icon="check"/></i><span>{{ $t('analyzeModify-explain') }}</span>
                </li>
                <li><i class="icon-check"><font-awesome-icon icon="check"/></i><span>{{ $t('analyzeModify-dropdown') }}</span>
                </li>
                <li><i class="icon-check"><font-awesome-icon icon="check"/></i><span>{{ $t('analyzeModify-nohex') }}</span></li>
                <li><i class="icon-check"><font-awesome-icon icon="check"/></i><span>{{ $t('analyzeModify-copyPaste') }}</span></li>
              </ul>
          </b-col>
          <b-col md="8" class="text-right" data-aos="fade-left">
            <img class="img-responsive" src="images/inspector.png" alt="AFPWorX Inspector"
                 style="box-shadow: 10px 20px 30px grey;"/>
          </b-col>
        </b-row>

        <b-row class="align-items-center" style="margin-top: 5em;">
          <b-col md="7" data-aos="fade-left">
            <div align="center" class="embed-responsive embed-responsive-16by9">
              <video class="embed-responsive-item" preload="auto" autoplay="autoplay" loop="loop" muted="muted">
                <source src="https://afpworx--static.s3.eu-central-1.amazonaws.com/Understand2.m4v" type="video/mp4"/>
                <source src="https://afpworx--static.s3.eu-central-1.amazonaws.com/Understand2.webm"
                        type="video/webm"/>
                <source src="https://afpworx--static.s3.eu-central-1.amazonaws.com/Understand2.ogv" type="video/ogg"/>
              </video>
            </div>
          </b-col>
          <b-col md="5" data-aos="fade-right">
            <div class="mt">
              <a name="understand"></a>
              <h3>{{ $t('understandAFP') }}</h3>
              <p>{{ $t('understandAFP-desc') }}</p>
              <ul class="list-nav">
                <li><i class="icon-check"><font-awesome-icon icon="check"/></i><span>{{ $t('understandAFP-viewer') }}</span>
                </li>
                <li><i class="icon-check"><font-awesome-icon icon="check"/></i><span>{{ $t('understandAFP-find') }}</span>
                </li>
              </ul>
            </div>
          </b-col>
        </b-row>

        <b-row class="align-items-center" style="margin-top: 5em;">
          <b-col md="4" data-aos="fade-right">
            <div class="mt">
              <a name="fix"></a>
              <h3>{{ $t('fixAFP') }}</h3>
              <p>{{ $t('fixAFP-desc') }}</p>
              <ul class="list-nav">
                <li><i class="icon-check"><font-awesome-icon icon="check"/></i><span
                >{{ $t('fixAFP-validate') }}</span>
                </li>
                <li><i class="icon-check"><font-awesome-icon icon="check"/></i><span>{{ $t('fixAFP-highlight') }}</span></li>
                <li><i class="icon-check"><font-awesome-icon icon="check"/></i><span>{{ $t('fixAFP-check') }}</span></li>
                <li><i class="icon-check"><font-awesome-icon icon="check"/></i><span>{{ $t('fixAFP-fix') }}</span></li>
              </ul>
            </div>
          </b-col>
          <b-col md="8" data-aos="fade-left">
            <img class="img-responsive" src="images/fix.png" style="box-shadow: 10px 20px 30px grey;"
                 alt="AFPWorX fixes AFP Problems"/>
          </b-col>
        </b-row>

        <b-row class="align-items-center" style="margin-top: 5em;">
          <b-col md="8" data-aos="fade-left">
            <img class="embed-responsive" src="images/extract.png" style="box-shadow: 10px 20px 30px grey;"
                 alt="AFPWorX extracts resources"/>
          </b-col>
          <b-col md="4" data-aos="fade-right">
              <a name="extract"></a>
              <h3>{{ $t('extract') }}</h3>
              <p>{{ $t('extract-desc') }}</p>
          </b-col>
        </b-row>

        <b-row class="align-items-center" style="margin-top: 5em;">
          <b-col md="5" data-aos="fade-right">
            <div class="mt">
              <a name="fast"></a>
              <h3>{{ $t('workFast') }}</h3>
              <ul class="list-nav">
                <li><i class="icon-check"><font-awesome-icon icon="check"/></i><span>{{ $t('workFast-seconds') }}</span>
                </li>
              </ul>
            </div>
          </b-col>
          <b-col md="7" data-aos="fade-left">
            <div align="center" class="embed-responsive embed-responsive-16by9">
              <video class="embed-responsive-item" preload="auto" autoplay="autoplay" loop="loop" muted="muted">
                <source src="https://afpworx--static.s3.eu-central-1.amazonaws.com/OpenFast2.m4v" type="video/mp4"/>
                <source src="https://afpworx--static.s3.eu-central-1.amazonaws.com/OpenFast2.webm" type="video/webm"/>
                <source src="https://afpworx--static.s3.eu-central-1.amazonaws.com/OpenFast2.ogv" type="video/ogg"/>
              </video>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>

    <div id="fh5co-testimonial">
      <div class="container">
        <div class="row" data-aos="fade-up">
          <div class="col-md-8 col-md-offset-2 text-center fh5co-heading">
            <h2>{{ $t('testimonial') }}</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4" data-aos="fade-up">
            <div class="testimony">
              <blockquote>
                &ldquo;<span>{{ $t('testimonial1') }}</span>&rdquo;
              </blockquote>
              <p class="author"><cite>{{ $t('testimonial1-author') }}</cite></p>
            </div>
          </div>
          <div class="col-md-4" data-aos="fade-up">
            <div class="testimony">
              <blockquote>
                &ldquo;<span>{{ $t('testimonial2') }}</span>&rdquo;
              </blockquote>
              <p class="author"><cite>{{ $t('testimonial2-author') }}</cite></p>
            </div>
          </div>
          <div class="col-md-4" data-aos="fade-up">
            <div class="testimony">
              <blockquote>
                &ldquo;<span>{{ $t('testimonial3') }}</span>&rdquo;
              </blockquote>
              <p class="author"><cite>{{ $t('testimonial3-author') }}</cite></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Newsletter/>

  </div>
</template>

<script>
import countTo from 'vue-count-to'
import Newsletter from '@/components/Newsletter'

export default {
  name: 'HomeView',
  components: {
    countTo, Newsletter
  },
  data: () => ({
    intersectionOptions: {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: [0, 1] // [0.25, 0.75] if you want a 25% offset!
    },
    animated: false
  }),
  methods: {
    onWaypoint({going}) {
      if (going === this.$waypointMap.GOING_IN && !this.animated) {
        setTimeout(() => {
          this.$refs.counter_sf.start()
          this.$refs.counter_sdf.start()
          this.$refs.counter_cs.start()
          this.$refs.counter_triplets.start()
          this.$refs.counter_goca.start()
        }, 400)
        this.animated = true
      }
    }
  }
}
</script>
