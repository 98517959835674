<template xmlns:th="http://www.w3.org/1999/xhtml">
  <div>

    <header id="fh5co-header" class="fh5co-cover fh5co-cover-sx" role="banner">
      <div class="overlay"></div>
      <div class="container">
        <b-row class="mt-5">
          <b-col md="8" class="offset-2 text-center">
            <div class="display-t">
              <div class="display-tc" data-aos="zoom-in">
                <h1>{{ $t('license-title') }}</h1>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </header>

    <div class="fh5co-section" v-if="!license">
      <div class="container">
        <b-row>
          <b-col md="10" data-aos="fade-up">
            <p v-html="$t('license-desc')"></p>
            <b-row class="mb-5">
              <b-col md="6">
                <b-img fluid :src="require('@/assets/images/firstTimeWizard.png')" style="box-shadow: 10px 20px 30px grey;" />
              </b-col>
              <b-col md="6">
                <b-img fluid :src="require('@/assets/images/licenseDialog.png')" style="box-shadow: 10px 20px 30px grey;" />
              </b-col>
            </b-row>
            <p v-html="$t('license-desc2')"></p>
            <b-row class="form-group">
              <b-col md="6">
                <input type="text" v-model="preName" class="form-control" :placeholder="$t('yourfirstname')"/>
              </b-col>
              <b-col md="6">
                <input type="text" v-model="name" class="form-control" :placeholder="$t('yourlastname')"/>
                <span v-for="error of v$.name.$errors" :key="error.$uid">{{ error.$message }}</span>
              </b-col>
            </b-row>

            <b-row class="form-group">
              <b-col md="12">
                <input type="text" v-model="company" class="form-control" :placeholder="$t('yourcompany')"/>
              </b-col>
            </b-row>

            <b-row class="form-group">
              <b-col md="12">
                <input type="text" v-model="email" class="form-control" :placeholder="$t('youremail')"/>
                <span v-for="error of v$.email.$errors" :key="error.$uid">{{ error.$message }}</span>
              </b-col>
            </b-row>

            <b-row class="form-group">
              <b-col md="12">
                <input type="text" v-model="activationCode" class="form-control" :placeholder="$t('activationCode')"/>
                <span v-for="error of v$.activationCode.$errors" :key="error.$uid">{{ error.$message }}</span>
              </b-col>
            </b-row>

            <b-row class="form-group">
              <b-col md="12">
                <input type="text" v-model="hwCode" class="form-control" :placeholder="$t('hwCode')"/>
                <span v-for="error of v$.hwCode.$errors" :key="error.$uid">{{ error.$message }}</span>
              </b-col>
            </b-row>

            <b-row v-if="errors" class="mb-3">
              <b-col md="12">
                <span v-for="error of errors" :key="error">{{ error }}</span>
              </b-col>
            </b-row>

            <div class="form-group">
              <button
                  :disabled="submitting"
                  class="g-recaptcha btn btn-primary"
                  @click="submit"
              >
                <span v-if="submitting"><b-spinner small ></b-spinner> {{ $t('sending') }}</span>
                <span v-else>{{ $t('sendrequest') }}</span>
              </button>
            </div>

          </b-col>
        </b-row>

      </div>
    </div>

    <div class="fh5co-section" v-else>
      <div class="container">
        <b-row>
          <b-col md="10" data-aos="fade-up">
            <h3>{{ $t('licenseKey') }}</h3>
            <code>{{ this.license }}</code>
          </b-col>
        </b-row>
      </div>
    </div>

    <Newsletter v-if="license"/>

  </div>
</template>

<script>
import Newsletter from '@/components/Newsletter'
import { email, required, isActivationCode, isHwCode } from '@/i18n-validators'
import { useVuelidate } from '@vuelidate/core'
import { apiUrl} from "@/api";

export default {
  name: "LicenseView",
  components: {
    Newsletter
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      submitting: false,
      msgPage: false,
      preName: '',
      name: '',
      company: '',
      email: '',
      activationCode: '',
      hwCode: '',
      license: '',
      errors: [],
    }
  },
  validations() {
    return {
      name: { required },
      email: { required, email },
      activationCode: { required, isActivationCode },
      hwCode: { required, isHwCode }
    }
  },
  methods: {
    async submit() {

      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        return
      }

      this.errors.splice(0, this.errors.length)

      await this.$recaptchaLoaded()
      let tokenCaptcha = await this.$recaptcha('activate')

      const msg = {
        firstName: this.preName,
        lastName: this.name,
        company: this.company,
        email: this.email,
        code: this.activationCode,
        hwcode: this.hwCode,
        captcha: tokenCaptcha
      }

      const url = `${apiUrl()}/license`
      this.submitting = true
      try {
        let response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(msg)
        })

        if (response.status === 200) {
          response = await response.json()
          this.license = response.license
          this.msgPage = true
        } else {
          response = await response.json()
          this.errors.push(response.errorMessage)
        }

      } catch (e) {
        this.errors.push(`something went wrong (${e})`)
      }
      this.submitting = false
    }
  }
}
</script>

<style scoped>

</style>
