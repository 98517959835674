import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import { BootstrapVue, LayoutPlugin } from 'bootstrap-vue'
import VueWaypoint from 'vue-waypoint'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
//import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueCookies from 'vue-cookies'
import VueAnalytics from 'vue-analytics';
import {store} from "@/store";

import 'aos/dist/aos.css'
import 'magnific-popup/dist/jquery.magnific-popup.js'
import 'magnific-popup/dist/magnific-popup.css'
import './app.scss'
import './assets/style.css'

library.add(fas)
//library.add(fab)
Vue.component(FontAwesomeIcon.name, FontAwesomeIcon)

Vue.use(VueCookies)
Vue.use(BootstrapVue)
Vue.use(LayoutPlugin)
Vue.use(VueWaypoint)

if (Vue.prototype.$cookies.get('consent')) {
  let consent = Vue.prototype.$cookies.get('consent')
  store.state.consent.googleCaptcha = consent.split(',')[0] === 'true'
  store.state.consent.googleAnalytics = consent.split(',')[1] === 'true'
  store.state.consent.cookies = true
}

if (store.state.consent.googleCaptcha) {
  Vue.use(VueReCaptcha, {
    siteKey: '6LcdjJYgAAAAAEJbSb3ZKbsCQpW3rze294RXbdjk',
    loaderOptions: {
      explicitRenderParameters: {
        badge: "bottomleft"
      }
    }
  })
}

if (store.state.consent.googleAnalytics) {
  Vue.use(VueAnalytics, {
    id: 'UA-98177631-1',
    checkDuplicatedScript: true,
    router,
    disabled: store.state.consent.googleAnalytics
  })
}

Vue.config.productionTip = false

const vue = new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
})

vue.$mount('#app')
